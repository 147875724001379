import React from 'react';
import { Grid } from '@material-ui/core';
import logo from '../../assets/images/logo.png';


import ig from '../../assets/images/ig.png';
import fb from '../../assets/images/fb.png';
import tiktok from '../../assets/images/tiktok.png';
import email from '../../assets/images/email.png';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import '../../css/footer.css';


const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
};

const Footer = () => {
  return (
    // style={{paddingBottom:'10%', paddingTop:'5%'}
    <div className="footerDiv">
        <Grid container spacing={0} alignItems="center">
        
        <Box component={Grid} xs={1} sm={1} md={2} />

            <Grid item xs={2} sm={2} md={2}>
                <div className='mainLogoDiv'>
                    <img src={logo} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Box component={Grid} xs={2} sm={1} display={{  md:"none"}} />


            <Grid item xs={6} sm={3} md={3}>
                <div className='footerTextDiv'>
                    <div className='footerText'>
                    <Link to="/faq" onClick={handleLinkClick} className='footerText'>
                        Frequently Asked Questions
                    </Link>
                    </div>
                    <div className='footerText'>
                    <Link to="/privacy" onClick={handleLinkClick} className='footerText'>
                        Privacy Policy
                    </Link>
                    </div>
                    <div className='footerText'>
                    <Link to="/userAgreement" onClick={handleLinkClick} className='footerText'>
                        User Agreement
                    </Link>
                    </div>
                    <div className='footerText'>
                    <Link to="/terms" onClick={handleLinkClick} className='footerText'>
                        Terms of Use
                    </Link>
                    </div>
                    <div className='footerText'>
                    <Link to="/cookiePolicy" onClick={handleLinkClick} className='footerText'>
                        Cookie Policy
                    </Link>
                    </div>
                </div>
            </Grid>

            <Box component={Grid} xs={1} display={{  md:"none", sm:"none"}}/>


            <Box component={Grid} xs={3}   display={{  md:"none", sm:"none"}}/>



            <Grid item xs={6} sm={4} md={3}>
                <div className="socialMediaFooterContainer">
                    <div className='socialMediaFooterDiv'>
                        <div className='footerTextInfo'> Follow Us </div>
                        <div className="logoContainer">
                            <div className='socialMediaDiv'>
                            <a href="https://instagram.com/chalokamao">
                                <img src={ig} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </a>
                            </div>
                            <div className='socialMediaDiv'>
                            <a href="https://facebook.com/chalokamao">
                                <img src={fb} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </a>
                            </div>
                            <div className='socialMediaDiv'>
                            <a href="https://tiktok.com/@chalokamao">
                                <img src={tiktok} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </a>
                            </div>
                        </div>
                    </div>                   
                    <div className='socialMediaFooterDiv'>
                        <div className='footerTextInfo'> Contact Us </div>
                        <div className="logoContainer">
                            <div className='socialMediaDiv'>
                            <a href="mailto:contact@chalokamao.com">
                                <img src={email} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </a>

                            </div>
                        </div>
                    </div>  
                </div>  
            </Grid>

            <Box component={Grid} xs={3}   display={{  md:"none", sm:"none"}}/>


        </Grid>
        
    </div>
    
  );
};

export default Footer;
