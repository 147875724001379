import React from 'react';
import { Grid } from '@material-ui/core';
import '../css/terms.css';

import urdu1 from '../assets/images/faq/urdu1.png';
import urdu2 from '../assets/images/faq/urdu2.png';
import urdu3 from '../assets/images/faq/urdu3.png';
import urdu4 from '../assets/images/faq/urdu4.png';
import urdu5 from '../assets/images/faq/urdu5.png';
import urdu6 from '../assets/images/faq/urdu6.png';
import urdu7 from '../assets/images/faq/urdu7.png';
import urdu8 from '../assets/images/faq/urdu8.png';
import urdu9 from '../assets/images/faq/urdu9.png';
import urdu11 from '../assets/images/faq/urdu11.png';
import urdu12 from '../assets/images/faq/urdu12.png';
import urdu13 from '../assets/images/faq/urdu13.png';
import urdu14 from '../assets/images/faq/urdu14.png';
import urdu15 from '../assets/images/faq/urdu15.png';
import urdu16 from '../assets/images/faq/urdu16.png';
import urdu17 from '../assets/images/faq/urdu17.png';
import urdu18 from '../assets/images/faq/urdu18.png';
import urdu19 from '../assets/images/faq/urdu19.png';
import urdu20 from '../assets/images/faq/urdu20.png';
import urdu21 from '../assets/images/faq/urdu21.png';
import urdu22 from '../assets/images/faq/urdu22.png';
import urdu23 from '../assets/images/faq/urdu23.png';
import urdu24 from '../assets/images/faq/urdu24.png';
import urdu25 from '../assets/images/faq/urdu25.png';
import urdu26 from '../assets/images/faq/urdu26.png';

const faqStyle = {
    image: {
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
  };
 
const FaqUr = () => {
  return (

    <div className='div1' style={{color:'white', paddingTop:"5%",paddingBottom:"5%", background:"white"}}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={1} sm={1} md={1}/>
            <Grid item xs={10} sm={10} md={10}>     
                <div style={ faqStyle.image }><img src={urdu1} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu2} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu3} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu4} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu5} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu6} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu7} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu8} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu9} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu11} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu12} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu13} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu14} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu15} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu16} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu17} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu18} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu19} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu20} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu21} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu22} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu23} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu24} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu25} alt="ur" /></div>
                <div style={ faqStyle.image }><img src={urdu26} alt="ur" /></div>
            </Grid>
          <Grid item xs={1} sm={1} md={1}/>   
        </Grid>
</div>

);
};

export default FaqUr;