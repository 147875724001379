import '../App.css';
import Header from "../components/maincomponents/Header";
import Body from "../components/maincomponents/Body/Body";
import Footer from "../components/maincomponents/Footer";

function MainPage() {
  return (
    <div className="container">

      <Header/>
 
      <Body/>

      <Footer/>
    </div>
  );
}

export default MainPage;
