import React from 'react';
import { Grid } from '@material-ui/core';
import main1 from '../../../assets/images/image.png';
import Box from '@mui/material/Box';
import '../../../css/upperBody.css';


const UpperBody = () => {
  return (

      <div className="upperBodyDiv">
        <Grid container spacing={3} alignItems="center" style={{ width : '100%'}}>
            
            <Box component={Grid} item xs={1} sm={1} md={2} />


            <Grid item xs={10} sm={5} md={3}>
            {/* <img src={main1} alt="main1" style={{ width: '100%', alignContent: 'left'}}/> */}
              <img src={main1} alt="main1" className='imageUpperBody'/>
            </Grid>

            <Box component={Grid} item xs={1} display={{ sm:"none", md: "none" }} />
            <Box component={Grid} item xs={1} display={{ sm:"none", md: "none" }} />


            <Grid item xs={10} sm={6} md={5}>
              <div>
                <div className="whiteTextDiv">
                  {"WALK & WIN"}
                </div>
                <div className="greenTextDiv">
                  {"REWARDS"}
                </div>
              </div>
            </Grid>


            <Box component={Grid} item xs={1} md={2} display={{ sm:"none", xs: "none" }} />


        </Grid>
      </div>
      );
};

export default UpperBody;
