import React from 'react';
import { Grid } from '@material-ui/core';
import phone2 from '../../../assets/images/phone2.png';
import greenbg from '../../../assets/images/greenbg.png';
import playIcon from '../../../assets/images/playIcon.png';
import iosIcon from '../../../assets/images/iosIcon.png';
import Box from '@mui/material/Box';
import '../../../css/bottomBody.css';


const BottomBody = () => {
  return (

    // <div style={{paddingBottom:'40%', paddingTop:'20%'}}>
    <div className="bottomMainDiv">
        
        <Grid container spacing={0} alignItems="center">

            <Box component={Grid} item xs={1} sm={2} md={3} />


            <Grid item xs={10} sm={8} md={6} className="greenBgDiv" style={{ backgroundImage:`url(${greenbg})` }} >

                <div className="greenBgDiv" style={{ display: 'flex', width:'100%'}} id="greenBgDiv" >

                    <div>
                    {/* <img src={phone2} alt="main1" style={{height:'420px', marginTop: '-20%'}} /> */}
                    <img src={phone2} alt="main1" className="bottomMainImageDiv" />
                    </div>

                    <div className="textLogoContainerDiv" style={{flex:1}}>
                       
                        <div className="purpleTextDiv" style={{ marginTop : "60px"}}>
                            DOWNLOAD THE FREE APP TODAY
                        </div>
                        <div className="whiteStepTextDiv" style={{ marginTop: '35px', color: 'white' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.pati.chalokamao.app">
                                <img src={playIcon} alt="main1" className="downloadLinkIconImage" />
                            </a>
                            </div>
                            <div className="whiteStepTextDiv" style={{ marginTop: '0px', color: 'white' }}>
                            <a href="https://apps.apple.com/app/chalo-kamao/id6449718263">
                                <img src={iosIcon} alt="main1" className="downloadLinkIconImage" />
                            </a>
                            </div>
                    </div> 
                </div>
            </Grid>

            <Box component={Grid} item xs={1} sm={2} md={3} />
        </Grid>

    </div>
    
  );
};

export default BottomBody;
