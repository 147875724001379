import React from 'react';
import '../../../css/body.css';
import UpperBody from './UpperBody';
import MiddleBody from './MiddleBody';
import BottomBody from './BottomBody';


const Body = () => {
  return (

    <>
      <UpperBody/>

      <MiddleBody/>

      <BottomBody/>
    </>



  );
};

export default Body;
