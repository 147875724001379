import React from 'react';
import { Grid } from '@material-ui/core';
import '../css/terms.css';

const Faq = () => {
  return (

    <div className='div1' style={{color:'white'}}>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={1} sm={1} md={1}/>
            <Grid item xs={10} sm={10} md={10}>     

                <br></br>
                <br></br>
                <center>

            <h1>
                Pati Game Studio
            </h1>
            <h1>
                Chalo Kamao
            </h1>
            <br></br>

            <u>
<p><strong>Frequently Asked Questions</strong></p>
</u> 
<br></br>
</center>

<br></br><ul>
    <li><strong>What is the Chalo Kamao app?</strong></li>
</ul>
<p>It is an application where you can both follow your steps on a daily basis and convert your steps into CKP (Chalo Kamao Points) by watching ads. With the converted CKPs, you can receive constantly updated rewards from our store!</p>

<br></br><ul>
    <li><strong>Is the Chalo Kamao app free?</strong></li>
</ul>
<p>Yes, the app is entirely free to download and use.</p>

<br></br><ul>
    <li><strong>How do I create an account?</strong></li>
</ul>
<p>When you open the app, you can log in with your Google account. If you have an invitation code, log in by adding this code, then the CKP reward (Invite &amp; Earn Bonus) will be defined for you and your friend who invited you as soon as you convert your first steps.</p>

<br></br><ul>
    <li><strong>What is CKP?</strong></li>
</ul>
<p>CKP (Chalo Kamao Points) is the in-app currency that you earn when you convert your daily steps and make purchases in the store.</p>

<br></br><ul>
    <li><strong>How do I collect CKP?</strong></li>
</ul>
<p>You can convert the steps you take during the day to CKP by watching ads. The more you walk, the more you earn CKP! But please keep in mind that the daily limit is 20,000 steps which you can convert.</p>

<br></br><ul>
    <li><strong>What can I do with CKP?</strong></li>
</ul>
<p>You can get whatever you choose from our store with CKP. Do not forget to check the store regularly; it is constantly updated!</p>

<br></br><ul>
    <li><strong>Can I convert CKP into cash?</strong></li>
</ul>
<p>CKP cannot be converted into cash. It is used only for purchasing rewards within the app.</p>

<br></br><ul>
    <li><strong>What is the validation period for my earned CKP?</strong></li>
</ul>
<p>Validation time is 1 year starting from the time you join Chalo Kamao, for your CKP. Every membership anniversary, your unused CKPs will be deleted and reset.</p>

<br></br><ul>
    <li><strong>Can I transfer my CKP to another account?</strong></li>
</ul>
<p>CKPs cannot be transferred to another account. It&apos;s all account based points.</p>

<br></br><ul>
    <li><strong>How do my steps turn into rewards?</strong></li>
</ul>
<p>When you want to convert your steps, you can watch ads by clicking the &quot;convert steps&quot; button. When the ad is over, your steps will be turned into CKP.</p>

<br></br><ul>
    <li><strong>Can I convert my steps whenever I want?</strong></li>
</ul>
<p>You can convert your steps by watching ads anytime during the day. But your daily steps will reset at 12:00pm. So, you have time until 11:59pm.</p>

<br></br><ul>
    <li><strong>What to do when my Energy is low?</strong></li>
</ul>
<p>As you walk, your energy gets lower. By clicking on the &ldquo;lightning icon&rdquo; on the main screen, you can increase your energy and get the highest multiplier for your steps.</p>

<br></br><ul>
    <li><strong>How can I receive products?</strong></li>
</ul>
<p>After converting your steps, you can choose any product from the store with the CKP defined in your account.</p>

<br></br><ul>
    <li><strong>How are the products shipped?</strong></li>
</ul>
<p>We send the actual products by Blu Ex Courier company. If you choose a digital code, you can find the details within the &ldquo;Rewards&rdquo; section in the app.</p>

<br></br><ul>
    <li><strong>Do I pay the delivery fee for the product I choose?</strong></li>
</ul>
<p>No. Chalo Kamao covers the delivery cost of all the rewards you choose. Only exception is; if the courier company can not deliver the product cause you are not present at the adress, the product returns to us. Afterwards, you should re-send the product by pressing the &ldquo;Re-send&rdquo; button in the &ldquo;Rewards&rdquo; section. But this 2nd time, the payment will be on your side.</p>

<br></br><ul>
    <li><strong>How long should I wait for a product to ship?</strong></li>
</ul>
<p>The approval of purchased digital codes takes place within 48 hours. Regular products are sent to the address you gave within 7 working days. Yo can tack this through progress from the &ldquo;Rewards&rdquo; section in the app.</p>

<br></br><ul>
    <li><strong>Is there any way I can earn more CKP?</strong></li>
</ul>
<p>Apart from converting your daily steps and completing bonuses, when your friends log into the app with your invitation code and convert their first steps, you both will earn rewards.&nbsp;</p>

<br></br><ul>
    <li><strong>What bonuses are in Chalo Kamao?</strong></li>
</ul>
<p>There are a lot of bonuses in Chalo Kamao app. Some of them are;&nbsp;&nbsp;Regular Bonus which requires your 7 days of consecutive log in; daily Watch &amp; Win Bonus and there are 1,000, 3,000, 5,000, 10,000, and 20,000 Step Bonuses that you can earn CKP as you complete the required steps.</p>

<br></br><ul>
    <li><strong>Can I add my friends?</strong></li>
</ul>
<p>You can add friends by clicking on the profile link of your friends or by searching for your friends in the search button.</p>

<br></br><ul>
    <li><strong>Can I invite my friends?</strong></li>
</ul>
<p>Yes! When your friend joins Chalo Kamao with your invitation code and converts their first steps, you both will get rewards (Invite &amp; Earn Bonus).</p>

<br></br><ul>
    <li><strong>Does Chalo Kamao app use extra battery power?</strong></li>
</ul>
<p>Chalo Kamao does not use extra battery power as it accesses your step information from the Apple Health or Google Fit apps.</p>

<br></br><ul>
    <li><strong>Can I use the app with my smartwatch?</strong></li>
</ul>
<p>If your smartwatch is compatible with Apple Health or Google Fit, Chalo Kamao can access the steps you take during the day through these applications.</p>

<br></br><ul>
    <li><strong>Why can&apos;t I watch ads?</strong></li>
</ul>
<p>Ads within the app are shown by ad providers. There may be issues with displaying ads during times when users are heavily watching ads. To avoid such a problem, we recommend that you convert your steps outside of the rush hours (the end of the day is the busiest time).</p>

<p>If this problem is not for couple of minutes and you can never watch ads, we suggest that you make sure not to use a Proxy, VPN or Ad Blocker and your DNS settings are on DHCP mode in Android and automatic on IOS devices.</p>

<br></br><ul>
    <li><strong>Why Aren&apos;t My Points Loading?</strong></li>
</ul>
<p>You may not have given the necessary permissions for Chalo Kamao to see the step data. Check this by following the steps below.</p>

<p>For iOS devices, go to Settings&gt;Privacy&gt;Health&gt;Chalo Kamao from your phone. You need to turn on all permissions here.</p>
<p>On Android devices, go to Settings&gt;Applications&gt;Chalo Kamao&gt;Permissions from your phone. You need to turn on all permissions here.</p>



<br></br><ul>
    <li><strong>I can&apos;t watch ads. What should I do?</strong></li>
</ul>
<p>Firstly, it should be noted that at times, it may take some time for our ad channels to deliver ads tailored to you. Occasionally, this period can be extended due to high demand. If you are experiencing this issue consistently, we kindly request the following from you:</p>
<p>- Remove any ad blockers, VPNs, or proxies from your phone,</p>
<p>- Ensure that the internet you are using is not from a restricted wireless network (such as a school or workplace),</p>
<p>- Verify that the DNS settings are set to DHCP on Android phones and automatic on iPhone phones,</p>
<p>- Ensure that the <a href="https://play.google.com/store/apps/details?id=com.google.android.webview" style={{color:"#3366FF"}}>Android System WebView</a> and 
<a href="https://play.google.com/store/apps/details?id=com.google.android.gms" style={{color:"#3366FF"}}> Google Play Services</a> applications are up to date.</p>
<p>- After updating all your settings in this manner, please try again using a mobile network.</p>







<p><br></br></p>
<p><br></br></p>

<p><strong>Didn&apos;t find the answer you were looking for? Contact Us</strong></p>
<p>If you have a question that is not answered in the FAQ, you can contact the Chalo Kamao support team by sending an e-mail to contact@chalokamao.com</p>



<p><br></br></p><p><br></br></p>

</Grid>

<Grid item xs={1} sm={1} md={1}/>   

</Grid>
</div>

);
};

export default Faq;