import React, { useState } from "react";
import "../../css/navbar.css";
import logo from '../../assets/images/logo.png';

function Header() {
  const handleLogoClick = () => {
    console.log('Main logo clicked!');
  };

  const handleGreenButtonClick = () => {
    window.location.href = 'http://chalokamao.com/faq';
  };

  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");

  const scrollToGreenBgDiv = () => {
    const greenBgDiv = document.getElementById("greenBgDiv");
    if (greenBgDiv) {
      const { top, height } = greenBgDiv.getBoundingClientRect();
      const offset = window.pageYOffset;
      const targetPosition = top + offset - (window.innerHeight - height) / 2;
      if (active !== "nav__menu") {
        setActive("nav__menu");
      }

      // Icon Toggler
      if (icon !== "nav__toggler") {
        setIcon("nav__toggler");
      }

      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    }
  };

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  return (
    <nav className="nav">
      <a href="#" className="nav__brand">
        <div class="mainLogoDivHeader" onClick={handleLogoClick}>
          <img src={logo} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      </a>

      <ul className={active}>
        <li>
        <div class="headerWhiteDiv">
          <a href="http://chalokamao.com/faq" style={{textDecoration: 'none'}}>How to use</a>
          </div>
        </li>

        <li>
          <div class="greenButtonDiv" onClick={scrollToGreenBgDiv}>
              Download the free app
          </div>
        </li>

        {/* style={{ cursor: "pointer" }} */}

        <li className={active} style={{ backgroundColor: 'transparent' }}></li>
      </ul>

      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Header;
