import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './InviteFriend.css';
import { ContentCopy, CheckCircle } from '@mui/icons-material';

import iosIcon from '../../assets/images/iosIcon.png';
import playIcon from '../../assets/images/playIcon.png';
import logo from '../../assets/images/logo.png'
import pattern from '../../assets/images/pattern.png'

function InviteFriend() {
  const codeRef = useRef(null);
  const { code } = useParams();
  const [isCopied, setIsCopied] = useState(false);

  const copyCode = () => {
    if (codeRef.current) {
      codeRef.current.select();
      document.execCommand('copy');
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500); // Reset the copied state after 1.5 seconds
    }
  };

  return (
    <div className="invite-code-container">
      <div className='invite-code-container-box'>
        <div style={{alignItems:'center', alignContent:'center', justifyContent: 'center' }}>
          <img src={logo} alt="main1" className='logo'/>
        </div>
        <h1 style={{fontStyle: 'italic'}}>
          Chalo Kamao
        </h1>
        <div className="invite-code-text">
          Your friend invites you to use Chalo Kamao. After downloading the application, sign up using the invitation code and earn the gift Chalo Kamao points.
        </div>
        <div className="invite-code-box">
          <div className="invite-code-inner-box">
            <div>
              <div className="invite-code-title-text"> Invite Code </div>
              <div style={{ flex:'1',alignItems:'center', alignContent:'center', justifyContent: 'center',border:'0px solid', borderRadius:'4px' }}>
                <input
                  type="text"
                  value={code}
                  ref={codeRef}
                  readOnly
                  className="invite-code-input"
                />
              </div>
            </div>
            <div style={{ flex:'1', marginTop:'3px',marginLeft:'-20px'}}>
              <button className="copy-icon" onClick={copyCode}>
                {isCopied ? <CheckCircle /> : <ContentCopy />}
              </button>
            </div>
          </div>
        </div>
      </div> 
      <div style={{ marginTop : '60px'}}>
        <a href="https://apps.apple.com/app/chalo-kamao/id6449718263">
          <img src={iosIcon} alt="main1" className='appleStoreIcon'/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.pati.chalokamao.app">
          <img src={playIcon} alt="main1" className='playStoreIcon'/>
        </a>
      </div>
    </div>
  );
}

export default InviteFriend;
