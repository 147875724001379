import React from 'react';
import { Grid } from '@material-ui/core';
import '../css/terms.css';





const Terms = () => {
  return (

    <div className='div1' style={{color:'white'}}>

        <Grid container spacing={3} alignItems="center">
            <Grid item xs={1} sm={1} md={1}/>
            <Grid item xs={10} sm={10} md={10}>     

                <br></br>
                <br></br>
                <center>

            <h1>
                Pati Game Studio
            </h1>
            <h1>
                Chalo Kamao
            </h1>
                <br></br>
<u><p><strong><span>Chalo Kamao APP TERMS AND CONDITIONS OF USE</span></strong></p></u>
<br></br>
</center>

<p><strong><span></span></strong></p>
<p><span>1- The Chalo Kamao app is an app where you can virtually earn points (CKP - Chalo Kamao Points) and accumulate them to exchange for gifts or other services offered. All you need to do is take a step. In exchange for confirming these steps, you will earn virtual points (CKP) for the number of steps under the following conditions.</span></p>
<p><span></span></p>
<p><span>2- You can earn (CKP) for a maximum of 20,000 steps and a minimum of 10 steps per day.</span></p>
<p><span ></span></p>
<p><span >3- Each 1000 steps is worth 1 currency point, provided that your energy is at 100% capacity. A 10% increase is added for bonus energy use.</span></p>
<p><span ></span></p>
<p><span >4-You can replenish your energy by pressing the "lightning" icon on the application, and with full energy, your steps will be calculated as 10% more valuable.</span></p>
<p><span ></span></p>
<p><span >Other colors and calculation rates are shown below.</span></p>

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
<table style={{width:'0.0pt',borderCollapse:'collapse',border:'none', textAlign:'center', alignItems:'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', fontFamily: 'Arial, Helvetica, sans-serif'}}>
    <thead style={{fontWeight: 'bold'}}>
        <tr>
            <td style={{width:'192pt',border:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'bottom'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>Energy Indicator</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderLeft:'none',padding:'0cm 5.4pt',verticalAlign:'bottom'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>Energy Multiplier</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderLeft:'none',padding:'0cm 5.4pt',verticalAlign:'bottom'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>Physical Step Taken</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderLeft:'none',padding:'0cm 5.4pt',verticalAlign:'bottom'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>(CKP) Value</span></p>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}></span></p>
            </td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>Bonus Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>1.1</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>22</span></p>
            </td>
        </tr>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>8 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>1</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20</span></p>
            </td>
        </tr>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>7 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.8</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>16</span></p>
            </td>
        </tr>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>6 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.6</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>12</span></p>
            </td>
        </tr>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>5 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.4</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>8</span></p>
            </td>
        </tr>
        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>4 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.2</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>4</span></p>
            </td>
        </tr>

        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>3 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.2</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>4</span></p>
            </td>
        </tr>

        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>2 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.2</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>4</span></p>
            </td>
        </tr>

        <tr>
            <td style={{width:'192pt',borderRight:'1pt solid rgb(134, 134, 134)',borderBottom:'1pt solid rgb(134, 134, 134)',borderLeft:'1pt solid rgb(134, 134, 134)',borderImage:'initial',borderTop:'none',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:' normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>1 Energy</span></p>
            </td>
            <td style={{width:'65.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>0.1</span></p>
            </td>
            <td style={{width:'68.25pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>20,000</span></p>
            </td>
            <td style={{width:'66.75pt',borderTop:'none',borderLeft:'none',borderBottom:'1pt solid rgb(134, 134, 134)',borderRight:'1pt solid rgb(134, 134, 134)',padding:'0cm 5.4pt',verticalAlign:'top'}}>
                <p style={{marginTop:'0cm',marginRight:'0cm',marginBottom:'.0001pt',marginLeft:'0cm',lineHeight:'normal',fontSize:'15px',fontFamily:'"Calibri",sans-serif',textAlign:'center'}}><span style={{fontSize:'16px',fontFamily: 'Arial, Helvetica, sans-serif',color:'#212529'}}>2</span></p>
            </td>
        </tr>
    </tbody>
</table>
</div>
<p><span >5- Steps must be converted to (CKP) on a daily basis. Steps do not carry over to the next day and are reset. Therefore, do not forget to convert your steps to (CKP) on a daily basis. From the lower limit, you can convert your steps to (CKP) at any intervals you want until 23:59 every day, and you can use bonuses until 23:50.</span></p>
<p><span ></span></p>
<p><span >6- You can exchange your (CKP) balance for the product of your choice from the app's store. Other details about the product are specified in the description of that product. However, you can only buy 1 of the same product unless otherwise stated. You can track your order through the app. Our company is not responsible for address inaccuracy-cargo company failures/damages/loss of the product in cargo and return of the product. However, our users can request to send the same product for the second time from the "Rewards" panel in the application. In this case, the shipping cost will belong to the buyer. In all these cases (CKP) is not refunded.</span></p>
<p><span ></span></p>
<p><span >7- It is important to check the store frequently to be informed about campaigns and product changes.</span></p>
<p><span ></span></p>
<p><span >8- (CKP) earned will be reset on membership anniversaries and will not carry over to the next membership year.</span></p>
<p><span ></span></p>
<p><span >9- Even if you delete your account, reinstalling the application will not delete your steps that have not been converted into (CKP).</span></p>
<p><span ></span></p>
<p><span >10- If it is understood that there is cheating in the use of the application by any method - the earned (CKP) are taken back and the membership of the relevant member is canceled. The membership of the member whose membership is canceled for this reason and other members whose connection is detected from the same IP address may also be canceled. In this case, users do not have the right to claim any rights and receivables against Pati Teknoloji Dış Ticaret A.Ş.</span></p>
<p><span >11- Due to the use of multiple ad providers, we recommend that you turn your steps to (CKP) in the early hours in order not to be negatively affected by both ad providers and technical reasons and intensity. Our company is not responsible for not providing advertisements for the above reasons. </span></p>
<p><span >12- Our company is not responsible for advertisements within the application. Advertisements are provided by advertisement provider companies.</span></p>
<p><span >13- This application features a reward system or a sweepstakes that is entirely provided by the app developer. Apple and Google are not involved in any way with this contest or sweepstakes and does not provide any sponsorship or support. The app developer is solely responsible for providing the rewards and organizing the sweepstakes. If you encounter any issues or problems while using the application, please contact the app developer directly. Apple and Google does not provide any warranty or assume any responsibility for this sweepstakes or reward system</span></p>

<p><span></span></p>

<br></br>
<br></br>
<br></br>

            </Grid>

            <Grid item xs={1} sm={1} md={1}/>   

        </Grid>
    </div>

  );
};

export default Terms;
